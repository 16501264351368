.fade-in {
  opacity: 1;
}

.token {
  text-align: center;
}

.heroText h1 {
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.card a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.card div {
  margin-bottom: 5px;
}

.fade-in {
  opacity: 1;
}
