.hero,
.utility,
.utilityContainer {
  width: 100%;
  padding: 2vw;
}

.hero,
.utilityContainer,
.utilityContent {
  display: flex;
  justify-content: space-between;
}

.heroText {
  font-size: 1.5vw;
  margin-top: auto;
  margin-bottom: auto;
}

.buttonWrapper {
  width: 25%;
}

.heroImage {
  width: 45%;
}

.utility {
  border-top-style: solid;
  text-align: center;
  font-size: 1.3vw;
}

.utilityImage {
  width: 100%;
  background-image: url("utility.jpg");
  background-size: cover;
  border-radius: 2vw;
  margin-right: 1vw;
}

.utilityItems {
  width: 100%;
  margin-left: 1vw;
}

.utilityItem {
  width: 100%;
  border-style: solid;
  border-radius: 2vw;
  padding: 2vw;
}

.utilityItem:hover {
  border-color: white;
}

.utilityText {
  text-align: left;
  padding: 1vw;
  display: block;
}

.utilityLogo {
  align-self: center;
  width: 8vw;
  height: 8vw;
}

.token {
  width: 100%;
  padding: 2vw;
  font-size: 1.3vw;
  border-top-style: solid;
  text-align: center;
}

.pieContainer {
  width: 100%;
  height: 35vw;
}

.centered {
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-left: 2vw;
}

.botboxes {
  width: 80%;
}

.linegraph {
  width: 100%;
}

.botbutton {
  width: 50%;
  margin: auto;
}
