* {
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  overflow-x: hidden;
  font-family: "Roboto Mono", monospace;
  color: white;
}

/*LINKS*/
a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: #ffcc00;
}

a:hover,
.utilityItem:hover,
.logo img:hover {
  transform: scale(1.1);
}

/*BUTTONS*/
.button {
  color: black;
  border-color: white;
  background-color: white;
  padding: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
  font-family: "Roboto Mono", monospace;
  border-style: solid;
  border-radius: 1vw;
  text-align: center;
}

.button:hover {
  cursor: pointer;
  background-color: black;
  color: #ffcc00;
  border-style: solid;
  border-radius: 1vw;
  border-width: 4px;
  transform: scale(1.1);
}

/*FADE IN*/
.pages {
  opacity: 0;
  transition: opacity 1s ease;
}

.pages.fade-in {
  opacity: 1;
}

/*FONT SIZE*/
.navItem,
.footerLinks {
  font-size: 2vw;
}

/*BACKGROUND COLORS*/
.header,
.footer,
.utilityItem,
.infoBox,
.infoPlate,
.card {
  background-color: rgba(15, 15, 15);
}

.flex {
  display: flex;
}

.spacebetween {
  justify-content: space-between;
}

.yellowText {
  color: #ffcc00;
}

.infoBox {
  border-style: solid;
  text-align: center;
  border-radius: 2vw;
  padding: 2vw;
  font-size: 1.5vw;
}

.input {
  font-size: 2vw;
  border-radius: 1vw;
  border-color: white;
}

.leftText {
  text-align: left;
}

.alignCenter {
  align-items: center;
}

.copy:hover {
  cursor: pointer;
  color: #ffcc00;
}

.width {
  width: 80%;
  margin: auto;
}

.margin {
  margin: 2vw;
}
