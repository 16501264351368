.header {
  display: flex;
  width: 100%;
  border-bottom-style: solid;
  padding: 0.5vw;
  position: fixed;
  z-index: 100;
}

.headerSpacer {
  padding: 1.9vw;
}

.title {
  display: flex;
  border-radius: 0.8vw;
  border-style: solid;
  width: 22%;
  margin-left: 3vw;
}

.title-image {
  width: 100%;
}

.navigation {
  width: 38%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.navItem {
  padding-right: 3vw;
}

.buy {
  margin-top: 0.3vw;
  font-size: 1.5vw;
  margin-left: 3vw;
}
