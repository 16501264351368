.footer {
  bottom: 0;
  width: 100%;
  border-top-style: solid;
  display: flex;
}

.footerLinks {
  display:flex;
  align-self: center;
  width: 30%;
  justify-content: space-evenly;
}

.logos {
  width: 20%;
}

.logo img {
  width: 3vw;
  margin: 1vw;
}

.footerMidSpacer {
  width: 58%
}